import { useEffect } from 'react';
import { useZodFormFieldSingle } from '~/components/zod/ZodForm';

export default function ZodFieldHidden(props: { name: string; value?: string | number }) {
  const [name, value, setValue] = useZodFormFieldSingle(props.name);

  useEffect(() => {
    if (props.value !== undefined) {
      setValue(props.value?.toString() ?? '');
    }
  }, [props.value]);

  return <input type="hidden" name={name} value={value ?? ''} />;
}
